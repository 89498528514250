export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      class="navbar  navbar-default navbar-expand-lg bg-body-tertiary navbar-fixed-top"
    >
      <div class="container">
        <button
          class="navbar-toggler collapsed custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand page-scroll" href="#menu">
          INDOSOFT
        </a>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li>
              <a href="#features" className="page-scroll">
                Pilih kami
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Temtang kami
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Produk & Jasa
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Client
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimoni
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Kontak
              </a>
            </li>
          </ul>
          <a href="https://novitracenter.com/webmail/" className=" btn-custom2">
            Client Area
          </a>
        </div>
      </div>
    </nav>
  );
};
